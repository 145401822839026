import { Tabs } from "devextreme-react";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { fetchFarmerById, sendParticipantUrl } from "../../api/farmers";
import { Button } from "../../components";
import MessagePopup from "../../components/messagePopup/messagePopup";
import { useLogin } from "../../cookies";
import CreateFarmerIcon from "../../resources/icons/CreateFarmer.svg";
import { getAvatarColor, getFirstLetters } from "../../utils/utils";
import BioData from "./bioData";
import FarmData from "./farmData";
import "./farmer.scss";
import ProductionData from "./productionData";
import { getFarmersPath } from '../../app-routes'
import { useProgram } from '../../programs'

const tabs = [
  {
    id: 0,
    text: "Bio data",
    content: (props) => <BioData {...props} />,
  },
  {
    id: 1,
    text: "Farm data",
    content: (props) => <FarmData {...props} />,
  },
  {
    id: 2,
    text: "Production data",
    content: (props) => <ProductionData {...props} />,
  },
];

const Farmer = (props) => {
  const program = useProgram();
  const { pathname } = useLocation();
  const { id: farmerId } = props.match.params;
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showSmsPopup, setShowSmsPopup] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [farmer, setFarmer] = React.useState({
    photo: "",
    firstName: "",
    lastName: "",
    participantCode: "",
  });
  const isMountedFetchFamer = React.useRef(false);
  const [editingState, setEditingState] = React.useState({
    0: {
      isEditing: false,
      buttonLabel: "Edit Bio Data",
    },
    1: {
      isEditing: false,
      buttonLabel: "Edit Farm Data",
    },
    2: {
      isEditing: false,
      buttonLabel: "Edit Production Data",
    },
  });
  const { token } = useLogin();
  const isEditMode = pathname.split("/").includes("edit");

  const fetchFarmer = React.useCallback(async () => {
    const splittedPath = pathname.split("/");
    const farmerId = splittedPath[splittedPath.length - 1];
    const res = await fetchFarmerById(token, farmerId);
    if (res.isOk) {
      const { photo, firstName, lastName, participantCode } = res.data;
      setFarmer({ photo, firstName, lastName, participantCode });
    } else {
      notify("Failed to fetch farmer", "error", 2000);
    }
  }, [pathname, token]);

  React.useEffect(() => {
    if (isEditMode && isMountedFetchFamer.current === false) {
      fetchFarmer();
      isMountedFetchFamer.current = true;
    }
  }, [isEditMode, fetchFarmer]);

  const handleTabChange = (args) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  };

  const handleCancel = () => {
    history.push(getFarmersPath(program.name));
  };

  const fullname = `${farmer.firstName} ${farmer.lastName}`;

  const handleEditClick = () => {
    setEditingState({
      ...editingState,
      [selectedIndex]: {
        ...editingState[selectedIndex],
        isEditing: true,
      },
    });
  };

  const handleSubmit = () => {
    setEditingState({
      ...editingState,
      [selectedIndex]: {
        ...editingState[selectedIndex],
        isEditing: false,
      },
    });
    fetchFarmer();
  };

  const handleSendSms = async () => {
    setLoading(true)
    const res = await sendParticipantUrl(token, {
      message,
      participantCode: farmer.participantCode,
    });
    const fullname = `${farmer.firstName} ${farmer.lastName}`;
    if (res.isOk) {
      notify(
        `Participant url to ${fullname} was sent successfully`,
        "success",
        2000
      );
    } else {
      notify(`Failed to send participant url to ${fullname}`, "error", 2000);
    }
    setLoading(false);
    handleCloseSmsPopup();
  };

  const handleCloseSmsPopup = () => {
    setShowSmsPopup(false);
    setMessage("");
  };

  const handleSendSmsClick = () => setShowSmsPopup(true);

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const isDisabled = editingState[selectedIndex].isEditing;

  return isEditMode ? (
    <>
      <div className={"headerBlock"}>
        <div className={"titleContainer"}>
          <div className={"image-container"}>
            {farmer.photo && farmer.photo.length > 0 ? (
              <div
                style={{
                  background: `url(${farmer.photo}) no-repeat #fff`,
                  backgroundSize: "cover",
                }}
                className={"user-image"}
              />
            ) : (
              <div
                style={{ background: getAvatarColor(fullname) }}
                className={"text-avatar"}
              >
                {getFirstLetters(farmer.firstName, farmer.lastName)}
              </div>
            )}
          </div>
          <h2>{fullname}</h2>
        </div>
        <div className={"buttons"}>
          <Button
            text={editingState[selectedIndex].buttonLabel}
            onClick={handleEditClick}
            disabled={isDisabled}
          />
          <Button
            text="Send participant url"
            onClick={handleSendSmsClick}
            disabled={isDisabled}
          />
          <Button text={"Close"} onClick={handleCancel} buttonStyle={"secondary"} disabled={isDisabled} />
        </div>
      </div>
      <div className={"content-block"}>
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedIndex}
          onOptionChanged={handleTabChange}
          scrollingEnabled
          disabled={isDisabled}
        />
        <div className={"dx-card responsive-paddings"}>
          {tabs[selectedIndex].content({
            isEditMode,
            isEditing: isDisabled,
            onSubmit: handleSubmit,
            farmerId: farmerId.toString(),
          })}
        </div>
        <MessagePopup
          options={[]}
          loading={loading}
          open={showSmsPopup}
          message={message}
          onClose={handleCloseSmsPopup}
          onMessageChange={handleMessageChange}
          onSendSms={handleSendSms}
          previewMessage={`Hello ${fullname}! ${
            message.length > 0 ? message : "*Your message*"
          } https://.../?user-id=...`}
        />
      </div>
    </>
  ) : (
    <>
      <div className={"contentBlock"}>
        <img src={CreateFarmerIcon} alt="farmerIcon" />
        <h2>{"Create Participant"}</h2>
      </div>
      <div className={"contentBlock"}>
        <div className={"dx-card responsive-paddings"}>
          <BioData isEditMode={isEditMode} onSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default Farmer;
