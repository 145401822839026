
// import { useEffect, useState, useRef } from "react";
import { useEffect, useState, useRef, useCallback, useMemo} from "react";
import {useParams } from "react-router";
import { SendAttendanceSheet, getPresentUsers, getSessionById } from "../../api/session";
import { fetchFarmers } from "../../api/farmers";
import { useLogin } from "../../cookies";
import { fetchCohortById } from "../../api/cohort";
import { renderCell } from "../../utils/utils";
import { DataGrid, Button as DXButton} from "devextreme-react";
import { Column, Selection, Editing, Lookup, FilterRow, Scrolling} from "devextreme-react/data-grid";
import { useHistory } from "react-router";
import { useProgram } from "../../programs";
import notify from 'devextreme/ui/notify'
import "./attendance.scss";
import { getCohortEditPath } from "../../app-routes";
import { Spinner, Button } from "../../components";
import { useScreenSize } from "../../utils/media-query";
import { CheckBox } from 'devextreme-react/check-box';
const AttendanceList = () => {
  const { sessionId, cohortId} = useParams();
  const [ presentUsers, setPresentsUsers] = useState([]);
  const [ absentUsers, setAbsentUsers] = useState([]);
  const [ stampSelectedFarmers, setStampSelectedFarmers] = useState([]);
  const [ stampAbsentUsers, setStampAbsentUsers] = useState([]);
  const [ session, setSession] = useState();


  const program = useProgram();
  const history = useHistory()
  const isMounted = useRef(false)
  const {token} = useLogin();

  const representedInitialValues = useMemo(() => {
    return {
      representedFirstname: null,
      representedLastname: null,
      representedGender: null,
      representedRepresentingQuality: null,
      representedYearOfBirth: null,
    };
  }, []);
  

  const { isSmall, isXSmall, isLarge, isXLarge, isMedium} = useScreenSize();

  const genders = [
    { display : "Male",
      value : "M"
    },
    {
      value : "F",
      display : "Female"
    },
    {
      value : "O",
      display : "Other"
    },
  ]

  const relations = [
    {value : "family"},
    {value : "employee"},
    {value : "other"},
  ]

  const  getSessionInfo = useCallback(async()=>{

    const res = await getSessionById(token, sessionId)
    if(res.isOk) {
      setSession(res.data)
    }

  }, [sessionId, token])

  const getUsers = useCallback(async()=>{
    // Get selected users
    const selectedUsersResponse = await getPresentUsers(token, sessionId);
    // Get cohort participants supposed to be in the session
    const cohortUsersRes = await fetchCohortById(token, cohortId)
    // Get other users
    const othersRes = await fetchFarmers(token)

    if(selectedUsersResponse.isOk && cohortUsersRes.isOk && othersRes.isOk) {
            // Arrange id names, because some are "userId", "participant" or "id", some of them are int and other are string
            // Add for everyone represented by initial values
      const cohortUsers = cohortUsersRes.data.participants.map(item => (
                {
                  id : Number(item.participant), 
                  fullName : `${item.firstName} ${item.lastName}`, 
                  firstName : item.firstName,
                  lastName : item.lastName,
                  photo : item.photo, 
                  isCohort : true,
                  representedBy : representedInitialValues,
                  isPresent : false,
                  ...representedInitialValues
                }
              ))

      // Array of ids of cohort participants to filter
      const cohortUserIds = cohortUsers.map(user => user.id);

      const selectedUsers = selectedUsersResponse.data.map(item => (
          {
            ...item,
            fullName : `${item.firstName} ${item.lastName}`,
            firstName : item.firstName,
            lastName : item.lastName,
            id : Number(item.id),
            representedBy : item.representedBy ? item.representedBy : representedInitialValues,
            isCohort : cohortUserIds.includes(item.id), 
            isPresent : true,
            representedFirstname : item.representedBy ? item.representedBy.firstName : representedInitialValues.firstName,
            representedLastname : item.representedBy ? item.representedBy.lastName : representedInitialValues.lastName,
            representedYearOfBirth : item.representedBy ? item.representedBy.yearOfBirth : representedInitialValues.representedYearOfBirth,
            representedGender : item.representedBy ? item.representedBy.gender : representedInitialValues.gender,
            representedRepresentingQuality : item.representedBy ? item.representedBy.representingQuality : representedInitialValues.representedRepresentingQuality,
          }
        ))

      
      // Array of ids of selected users to filter
      const selectedUserIds = selectedUsers.map(user => user.id);


      const others = othersRes?.data.map(item => (
        {
          ...item,
          fullName : `${item.firstName} ${item.lastName}`,
          id : Number(item.userId),
          isPresent : false,
          ...representedInitialValues
        }))


      // Remove selected users and cohort from others 
      others.data = others.filter(user =>{
        return !cohortUserIds.includes(user.id) && !selectedUserIds.includes(user.id)
      })
      const data = cohortUsers.filter(user =>{
        return !selectedUserIds.includes(user.id)
      })

      setAbsentUsers(others.data)
      setPresentsUsers([...data, ...selectedUsers])
    }
 
  }, [cohortId, representedInitialValues, sessionId, token])

  useEffect(() => {
    if (!isMounted.current) {
      getUsers();
      getSessionInfo();
      isMounted.current = true
    }
  }, [ sessionId, cohortId, getSessionInfo, getUsers])

  // Handle row style for present user that are not in the cohort
  const handleRowPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.isCohort !== true) {
          e.rowElement.style.backgroundColor = "#CED7D7";
      }
  }
  }

  // Hide checkbox if user is already in the cohort
  const CellPrepared = (e) => {
    if(e.rowType === 'data' && e.column.command === 'select' && e.row.data.isCohort) {
      e.cellElement.style.visibility = 'hidden';
    }

  }

  const removeFromPresent = (data) => {
  // Add to absent users 
    data.isPresent = false;
    const absentsData = [...absentUsers];
    absentsData.push(data);
    setAbsentUsers(absentsData);

    // Remove from attending  users
    const users = [...presentUsers];
    const updatedData = users.filter(item => item.id !== data.id);
    setPresentsUsers(updatedData);  
  }

  const handleRowUpdated= (e) => {
    // Find the right object index to update
    const index = presentUsers.findIndex((item) => item.id === e.key.id);

    
    if (index !== -1) {
      // If user is not in the cohort and was marked as not present, remove it from the list
      if(!e.data.isCohort && !e.data.isPresent){
        removeFromPresent(e.data)
      }
    }

    // If a user was represented by someone else, set isPresent to true
    if(e.data.representedFirstname || e.data.representedLastname || e.data.representedYearOfBirth || e.data.representedGender || e.data.representedRepresentingQuality){
      e.data.isPresent = true
      e.component.refresh()
    }
  
  };
  
  // update selected users to set as present
  const handleSelectionChange = (e) => {
    setStampSelectedFarmers(e.selectedRowsData);
  };

  // update selected users to set as absents
  const handlePresentChange = (e) => {
    setStampAbsentUsers(e.selectedRowsData);
  };

  // Push absent user to present user
  function removeFromAbsent(){
    const data = stampSelectedFarmers.map(item => ({
      ...item,
      isPresent: true,
      representedBy : representedInitialValues,
      ...representedInitialValues
    }));
    
    const updatedSelection = [...presentUsers, ...data];

    setPresentsUsers(updatedSelection);
    
    setStampSelectedFarmers([]);
    // Remove from absent users 
    const selectedIds = updatedSelection.map((selected) => selected.id);
    const filteredData = absentUsers.filter((farmer) => {
      return !selectedIds.includes(farmer.id);
    });

    setAbsentUsers(filteredData);
  };

function arrayMovetoAbsent(){
    stampAbsentUsers.forEach(element => {
      removeFromPresent(element)
    });
  }
  
function handleNavigation (){
    history.push({ pathname :getCohortEditPath(program.name, cohortId), state : {back : "session"}})
  }

  const isRepresentedInfoComplete = (user) => {
    // list of "represented by" fields that need to be checked.
    const representedFields = ["representedFirstname", "representedLastname", "representedYearOfBirth", "representedGender", "representedRepresentingQuality"];
    // Filter fields that are not empty
    const filledFields = representedFields.filter(field => user[field]);
    // Check if all fields are either fully filled or fully empty.
    return filledFields.length === 0 || filledFields.length === representedFields.length;
  };

const handlePresentChangeCheckBox =(e)=>{
  var dataSource = presentUsers;
  dataSource.map(user => user.isPresent = e.value)
  setPresentsUsers(dataSource)
}

const IsPresentTemplate =() =>{
  return (
  
        <CheckBox defaultValue={false} onValueChanged={handlePresentChangeCheckBox} />

  )
}

async function handleValidate(){
  let data
  const invalidUsers = presentUsers.filter(user => !isRepresentedInfoComplete(user));
    
  // If any of the represented fields is not filled, notify the user
  if (invalidUsers.length > 0) {
    notify(`Please complete all 'represented by' fields or leave them all empty for user ${invalidUsers[0].fullName}`, "error", 2000);
    return;
  }

  data = presentUsers
  .filter(item => item.isPresent === true)
  .map(user => ({
    userId: user.id,
    sessionId: sessionId,
    // If he was represented by someone else, use it, else null
    representedBy: user.representedFirstname && user.representedLastname ? {
      firstname: user.representedFirstname,
      lastname: user.representedLastname,
      yearOfBirth: user.representedYearOfBirth,
      gender: user.representedGender,
      representingQuality: user.representedRepresentingQuality
    } : null
  }));

    if (data.length === 0) {
      notify("Please select at least one user", "error", 2000);
    } else {
      const res = await SendAttendanceSheet(token, data)
      if(res.isOk){
        notify(res.data.message,"success", 2000)
      }
    }
}

const renderIsPresent = (data) => {
  return (
    <Column headerCellRender={IsPresentTemplate} caption="Present" dataField="isPresent" width={100} allowReordering={false}>
    <CheckBox defaultValue={false} />
  </Column>
  )
  
}

    return (
<>
      <div className="header">
       { session ? <h2>Attendance list for {session.name} session n°{session.sessionNumber}</h2> : null} 
        <div className="buttons">
          <Button text="Go back" onClick={handleNavigation} buttonStyle={"secondary"}/>
          <Button text="Validate"  onClick={handleValidate} />
        </div>
      </div>
        <div className="flex">
      {isMounted.current ?     
      <>
            <DataGrid
            id="gridContainer"
            dataSource={presentUsers}
            allowColumnResizing={true}
            onRowPrepared={handleRowPrepared}
            onCellPrepared={CellPrepared}
            onRowUpdated={handleRowUpdated}
            onSelectionChanged={handlePresentChange}
            showBorders={true}
            noDataText="No participants in cohort"
            width={"90%"}
            columnWidth={130}
            keyExpr={"id"}
          >
            <Scrolling mode="standard"/>
          <FilterRow visible={true}/>
          <Selection
              mode="multiple"
              selectAllMode={true}
              icon="checkbox"
            />
            <Editing
              mode="cell"
            allowUpdating={true}
          />
          <Column allowEditing={false} dataField="fullName" cellRender={renderCell} width={300}/>
          {(isXSmall || isSmall || isMedium) && renderIsPresent()}
          <Column caption="Represented by" alignment="center">
          <Column dataField="representedFirstname" caption="Name" allowFiltering={false}/>
          <Column dataField="representedLastname" caption ="Lastname" allowFiltering={false}/>
          <Column dataField="representedYearOfBirth" caption ="Year of birth" allowFiltering={false}/>
          <Column dataField="representedGender" caption="Gender" allowFiltering={false} width={80}  >
          <Lookup dataSource={genders} displayExpr="display" valueExpr="value" allowClearing={true} />
        </Column>
          <Column 
          calculateDisplayValue={data => data.representedBy.representingQuality}
          dataField="representedRepresentingQuality" caption ="Quality"
          allowFiltering={false}
          width={80}
          > 
          <Lookup dataSource={relations} displayExpr="value" valueExpr="value" allowClearing={true} />
        </Column>

        </Column>
        {(isLarge || isXLarge) && renderIsPresent()}

          </DataGrid>

          <div className={ "actionButtons"}>
          <DXButton
            className={"moveToCohortButton"}
            onClick={removeFromAbsent}
            icon={"arrowup"}
            text=""
          />
          <DXButton
            className={"moveToCohortButton"}
            onClick={arrayMovetoAbsent}
            icon={ "arrowdown"}
            text=""
          />

          </div>

          <DataGrid
            id="gridContainer"
            dataSource={absentUsers.length > 0 ? absentUsers : []}
            showColumnLines={true}
            showRowLines={false}
            showBorders={true}
            allowColumnResizing={true}
          //  keyExpr={"id"}
            width={"90%"}
            columnAutoWidth={true}
            onSelectionChanged={handleSelectionChange}
          >
          <FilterRow visible={true} />
          <Selection
              mode="multiple"
              selectAllMode={true}
              showCheckBoxesMode={"onClick"}
              icon="checkbox"
            />
          <Column caption ="Full Name" dataField="fullName" cellRender={renderCell} />
          </DataGrid>

          <div className={"buttons buttonsDown"}>
          <Button
            text={"Validate"}
            onClick={handleValidate}

          />
          <Button
            text="Cancel"
            buttonStyle={"secondary"}
            onClick={() => history.goBack()}
          />
          </div>

          </> 
      : <Spinner/>}
       

      </div>

      </>
    );

}


export default AttendanceList;
