import { Accordion } from "devextreme-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchCohortById, updateAllowedChaptersForCohort } from "../../api/cohort";
import { getCourseStructure } from "../../api/courses";
import { DataGrid } from "devextreme-react";

import { Column, Selection } from "devextreme-react/data-grid";
import { useLogin } from "../../cookies";
import notify from "devextreme/ui/notify";
import { useHistory, useLocation } from "react-router";
import { getAvatarColor } from "../../utils/utils";
import {Button} from "../../components";
import "./cohort.scss";
import { getCohortsPath } from '../../app-routes'
import { useProgram } from '../../programs'
import { Spinner } from "../../components";

//this page handle the distribution of courses
const CoursesDetails = () => {
  const program = useProgram();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [cohortName, setCohortName] = useState("");
  const [loading, setLoading] =useState(false);

  const { token } = useLogin();
  const { pathname } = useLocation();
  const history = useHistory();

  const splittedPath = pathname.split("/");
  const cohortId = splittedPath[splittedPath.length - 1];

  const fetchCourseDetails = useCallback(
    async (course) => {
      setLoading(true)
      const res = await getCourseStructure(token, course.slug, program.id);
      if (res.isOk) {
        const { chapters } = res.data;
        const chaptersWithFilter = chapters.map((chapter) => {
          if (course.allowedChapters) {
            if (course.allowedChapters.includes(chapter.id.toString())) {
              return { ...chapter, selected: true };
            } else {
              return { ...chapter, selected: false };
            }
          } else {
            return { ...chapter, selected: false };
          }
        });
        if (course.allowedChapters) {
          setLoading(false)
          return {
            ...res.data,
            completed: course.completed,
            started: course.started,
            sessionHours: course.sessionHours,
            chapters: chaptersWithFilter,
            allowedChapters: course.allowedChapters,
          };
        } else {
          setLoading(false)
          return {
            ...res.data,
            completed: course.completed,
            started: course.started,
            sessionHours: course.sessionHours,
            chapters: chaptersWithFilter,
            allowedChapters: [],
          };
        }
      } else{
        setLoading(false)
        notify(
          `Failed to fetch details for course ${course.slug}`,
          "error",
          2000
        );}
    },
    [token, program.id]
  );

  const fetchCohort = useCallback(async () => {
    const res = await fetchCohortById(token, cohortId);

    if (res.isOk) {
      const { courses, name } = res.data;
      const coursesDetails = await Promise.all(
        courses.map((item) => fetchCourseDetails(item))
      );

      // filter out undefined values / courses not found by wordpress
      const courseDetailsFilter = coursesDetails.filter((course) => course !== undefined)

      setSelectedCourses(courseDetailsFilter);
      setSelectedItems(courseDetailsFilter);

      setCohortName(name);
    } else notify("Failed to fetch cohort", "error", 4000);
  }, [token, fetchCourseDetails, cohortId]);

  useEffect(() => {
    fetchCohort();
  }, [fetchCohort]);

  const handleCancel = () => {
    history.push(getCohortsPath(program.name));
  };




  const ChapterGrid = ({ data }) => {
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [loading, setLoading] = useState(false);
    const chaptersDataRef = useRef(null);
    const { pathname } = useLocation();

    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const chaptersColumn = [{ key: "title", label: "Title" }];
    const isSelectedChapters = ["selected", "=", true];

    const handleUpdate = async () => {
      setLoading(true);

      const allowedChapters = selectedChapters.map(chapter => {
        return chapter.id.toString()
      })

      const res = await updateAllowedChaptersForCohort(token, {
        cohortId: cohortId,
        courseSlug: data.slug,
        allowedChapters:
          allowedChapters
      })

      if (res.isOk) {

          notify("Cohort updated", "info", 2000);
        }
      else {
        notify(res.message, "error", 2000);
      }
      setLoading(false);
    };

    const handleChaptersChange = async (e) => {
      const chapterSelection = await chaptersDataRef.current.instance.getSelectedRowsData();
      setSelectedChapters(chapterSelection);
    };

    const renderCourseCell = (props) => {
      const { data, value } = props;

      const renderAvatar = () => {
        return props.data.imageUrl ? (
          <div
            style={{
              background: `url(${data.imageUrl}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        ) : (
          <div
            style={{ background: getAvatarColor(value) }}
            className={"text-image"}
          >
            {value.substring(0, 2)}
          </div>
        );
      };

      return (
        <div className={"userinfo"}>
          <div className={"image-container"}>{renderAvatar()}</div>
          <div className={"username"}>{props.value}</div>
        </div>
      );
    };

    return (
      <div>
        <DataGrid
          id="gridContainer"
          ref={chaptersDataRef}
          dataSource={data.chapters}
          showColumnLines={true}
          showRowLines={false}
          hoverStateEnabled={true}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="slug"
          defaultSelectionFilter={isSelectedChapters}
          onSelectionChanged={handleChaptersChange}
        >
          <Selection
            mode="multiple"
            selectAllMode={true}
            showCheckBoxesMode={"always"}
            deferred={true}
          />
          {chaptersColumn.map((column) => (
            <Column
              key={column.key}
              dataField={column.key}
              caption={column.label}
              dataType={column.dataType}
              cellRender={renderCourseCell}
            />
          ))}
        </DataGrid>
        <Button
          text="Update"
          loading={loading}
          onClick={handleUpdate}
        />
      </div>
    );


  };


  const CustomTitle = (data) => {
    const renderAvatar = () => {
      return data.imageUrl ? (
        <div
          style={{
            background: `url(${data.imageUrl}) no-repeat #fff`,
            backgroundSize: "cover",
          }}
          className={"user-image"}
        />
      ) : (
        <div className={"text-image"}></div>
      );
    };


    return (
      <div className={"userinfo"}>
        <div className={"image-container"}>{renderAvatar()}</div>
        <div>{data.title}</div>

      </div>
    );
  };

  const selectionChanged = (e) => {
    let newItems = [...selectedItems];
    e.removedItems.forEach((item) => {
      let index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  return (
    <div>
      <h5>Courses for cohort {cohortName}</h5>
      {loading ? <Spinner /> :       
      <Accordion
        dataSource={selectedCourses}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        selectedItems={selectedItems}
        onSelectionChanged={selectionChanged}
        itemTitleRender={CustomTitle}
        itemRender={(data) => (
          <ChapterGrid data={data} />
        )}
      />}

      <Button
        text="Back to cohort list"
        onClick={handleCancel}
        buttonStyle={"secondary"}
      />
    </div>
  );
};

export default CoursesDetails;
