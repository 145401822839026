import React, { useEffect } from 'react'
import { useLogin } from '../cookies'

export default function Logout() {
  const { removeUser } = useLogin();

  useEffect(() => removeUser(),
    [removeUser]);

  return (
    <></>
  );
}
