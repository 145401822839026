import { Button } from "devextreme-react";
/**
 * returns first letters of name
 * @param {string} firstName firstname of the user
 * @param  {string} lastName lastname of the user
 */
export const getFirstLetters = (firstName, lastName) => {
  if (firstName.length > 0 && lastName.length > 0) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }
};

/**
 * returns a hsl color based on given name
 * @param {string} name
 */
export const getAvatarColor = (name) => {
  let hashCode = 0;
  for (let i = 0; i < name.length; i++) {
    hashCode = name.charCodeAt(i) + (hashCode << 4);
  }
  const h = hashCode % 360;
  return `hsl(${h}, 50%, 75%)`;
};

/**
 * Returns a encode base64 string
 * @param {File} file
 */
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Returns given obj with null values instead of empty strings coming from the form)
 * @param {object} obj
 */
export const processNullValues = (obj) => {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    if ((typeof obj[propName] === "string" && obj[propName].trim() === "") || obj[propName] === "") {
      obj[propName] = null;
    }
  }
  return obj;
};

export const sleep = ms => new Promise(r => setTimeout(r, ms));


export 
const renderCell = (props) => {
  const { data, value } = props;

  const renderAvatar = () => {
    return props.data.photo ? (
      <div
        style={{
          background: `url(${data.photo}) no-repeat #fff`,
          backgroundSize: "cover",
        }}
        className={"user-image"}
      />
      ) : (
      <div
        style={{ background: getAvatarColor(value) }}
        className={"text-image"}
      >
      {getFirstLetters(data.firstName, data.lastName)}
      </div>
      );
    };

  return (
    <div className={"userinfo"}>
      <div className={"image-container"}>{renderAvatar()}</div>
      <div className={"username"}>{props.value}</div>
      {data.isAmbassador ? <Button icon="favorites" text="" /> : ""}
    </div>
  );
};

export const sessionAlert = "SESSION_ALERT";
export const notificationCohort = "NOTIFICATION_COHORT";