import { default as React  } from "react";
import { useLocation } from "react-router";
import { useProgram } from "../../programs";


const ReportingDashboard = () => {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const key = pathArray[3]
  const program = useProgram()



  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
      <iframe 
      title="reporting"
      src={`https://dashboard.reporting.sutti.app/public/dashboard/${key}?program=${program.id}#hide_parameters=program,id`} 
      frameborder="1" 
      width="100%"
      height="1000"
       >

      </iframe>
      </div>
    </>
  );
};

export default ReportingDashboard;
