import { useState } from "react";
import { 
  Popup, 
  TextArea, 
  RadioGroup
} from "devextreme-react";
import { Button } from "..";
import "./messagePopup.scss";
  
const messageTypes = [
  'sms', 'notification'
]

const MessageToCohort = ({
  open,
  onClose,
  message,
  onMessageChange,
  onSendMessage,
  loading,
  cohortName,
  selectedMessageType,
  setSelectedMessageType
}) => {
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [nbSms, setNbSms] = useState(1);

  const countChar = (e) => {
    setTextAreaCount(e.event.target.value.length);
    setNbSms(textAreaCount > 140 ? Math.floor(textAreaCount / 140 + 1) : 1);
  }

  return (
    <Popup
      visible={open}
      onHiding={onClose}
      dragEnabled={false}
      closeOnOutsideClick={true}
      showTitle={true}
      title={"Send a message to " + cohortName} 
      width={500}
      height={330}
    >
      <div className="dx-field-label">Message</div>
      <p className="nbSms">{nbSms} SMS</p>
      <p className="SmsNbChar">{textAreaCount}/140</p>
      <TextArea
        value={message}
        onKeyDown={countChar}
        onValueChange={onMessageChange}
        valueChangeEvent={"keyup"}
        placeholder={"Enter your message"}
        label="Message"
      />

      <div className="dx-field">
        <div className="dx-field-label">Message type</div>
        <div className="dx-field-value">
          <RadioGroup 
            items={messageTypes} 
            defaultValue={messageTypes[0]} 
            layout="horizontal" 
            value={selectedMessageType}
            onValueChanged={(e) => setSelectedMessageType(e.value)}
          />
        </div>
      </div>

      <Button
        text={"Send"}
        onClick={onSendMessage}
        className={"sendButton"}
        loading={loading}
      />
    </Popup>
  );
};

export default MessageToCohort;