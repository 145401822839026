import React, { useState, useEffect, useCallback } from "react"
import { useLogin } from "../../cookies"
import { useHistory } from "react-router"
import {
  fetchFarmers,
  sendParticipantUrl,
  updateFarmer
} from "../../api/farmers"
import { getAvatarColor, getFirstLetters } from "../../utils/utils"
import {
  Button,
  Table,
  MessagePopup
} from "../../components"
import { confirm } from "devextreme/ui/dialog"
import notify from "devextreme/ui/notify"
import FarmerIcon from "../../resources/icons/Farmers.svg"
import "./farmers.scss"
import { getFarmerCreatePath, getFarmerEditPath } from '../../app-routes'
import { useProgram } from '../../programs'

const columns = [
  { key: "fullname", label: "Full name", visible: true },
  { key: "phoneNumber", label: "Phone number", visible: true },
  { key: "district", label: "District", visible: false },
  { key: "subdistrict", label: "Subdistrict", visible: false },
  { key: "participantCode", label: "Participant code", visible: true },
  {
    key: "enrollDate",
    label: "Enrollment date",
    dataType: "datetime",
    visible: true,
  },
  {
    key: "firstAccessDate",
    label: "First access date",
    dataType: "datetime",
    visible: false,
  },
  {
    key: "lastAccessDate",
    label: "Last access date",
    dateType: "datetime",
    visible: false,
  },
  { key: "userAgent", label: "Device Details", visible: false },
  { key: "oldestCropPlot", label: "Oldest Crop Date", visible: false }
]

const crudOptions = {
  create: true,
  edit: true,
  editInNewTab: true,
  delete: true,
  sendSms: true
}

//this page list all the farmers and also create farmer with a form
const Farmers = () => {
  const program = useProgram();
  const history = useHistory()
  const { token, user } = useLogin()

  const [loading, setLoading] = useState(true)
  const [farmers, setFarmers] = useState([])
  const [selectedFarmers, setSelectedFarmers] = useState([])
  const [showSmsPopup, setShowSmsPopup] = useState(false)
  const [message, setMessage] = useState("")
  const isMounted = React.useRef(false)

  const isDisabled = selectedFarmers.length === 0

  //this method get all the farmers profile
  const fetchAllFarmers = useCallback(async () => {
    const res = await fetchFarmers(token)
    if (res.isOk) {
      const farmersData = res.data.map((farmer) => {
        return {
          ...farmer,
          fullname: `${farmer.firstName} ${farmer.lastName}`,
        }
      })
      setFarmers(farmersData)
    }
    setLoading(false)
  }, [token])

  useEffect(() => {
    if (user && isMounted.current === false && token) { 
      fetchAllFarmers()
      isMounted.current = true
    }
  }, [fetchAllFarmers, user, isMounted, token])


  /**
   * HANDLER SECTION
   **/

  const handleSelectionChange = (e) => {
    setSelectedFarmers(e.selectedRowKeys)
  }

  const handleCreateClick = () => {
    history.push(getFarmerCreatePath(program.name))
  }

  const handleEditClick = (props) => {
    history.push(getFarmerEditPath(program.name, props.data.userId))
  }

  const handleSendSms = async () => {
    setLoading(true)
    const participantCodes = selectedFarmers.map((farmerId) => {
      return farmers.find((farmer) => farmer.userId === farmerId)
        ?.participantCode
    })

    const result = await Promise.all(
      participantCodes.map(async (participantCode) => {
        const res = await sendParticipantUrl(token, {
          participantCode,
          message,
        })
        return res.isOk ? res.isOk : participantCode
      })
    )

    const hasSentSmsToAll = result.every((e) => e === true)

    if (hasSentSmsToAll) {
      const messageToDisplay = () => {
        if (result.length > 1) {
          return "Sent participant url to all selected users successfully"
        }
        const username = farmers.find(
          (farmer) => farmer.userId === selectedFarmers[0]
        )?.fullname
        return `Participant url to ${username} was sent successfully`
      }
      notify(messageToDisplay(), "success", 2000)
    } else {
      const failedCodes = result.filter((e) => e !== true).join(", ")
      notify(
        `Failed to send participant url to following particpants: ${failedCodes}`,
        "error",
        7000
      )
    }
    setLoading(false)
    handleCloseSmsPopup()
  }

  const handleMessageChange = (key, value) => {
    setMessage(value)
  }

  const handleCloseSmsPopup = () => {
    setShowSmsPopup(false)
    setMessage("")
  }

  const handleSendSmsClick = () => setShowSmsPopup(true)

  const handleSendUrl = (e) => {
    handleSendSmsClick()
    setSelectedFarmers([e.row.data.userId])
  }

  const handleDelete = async (props) => {
    const userId = props.data.userId.toString(),
          response = await updateFarmer(token, userId, {
            userId,
            accountDisabled: true,
          })

    if (response.isOk) {
      await fetchAllFarmers()
      notify(
        `Participant ${props.data.fullname} was successfully deleted`,
        "success",
        2000
      )
    } else {
      notify(`Failed to delete participant ${props.data.fullname}`, "error", 2000)
    }
  }

  const handleDeleteFarmers = async () => {
    setLoading(true)
    const res = await Promise.all(
      selectedFarmers.map(async (id) => {
        const result = await updateFarmer(token, id.toString(), {
          userId: id.toString(),
          accountDisabled: true,
        })
        return result.isOk ? result.isOk : id
      })
    )

    const allSelectedDeleted = res.every((e) => e === true)

    const getUndeletedUsernames = () => {
      const undeletedIds = res.filter((e) => e !== true)
      const usernames = undeletedIds.map((id) => {
        const selectedFarmer = farmers.find(
          (farmer) => farmer.userId === id
        )
        return selectedFarmer?.fullname
      })
      return usernames.join(", ")
    }
    allSelectedDeleted
      ? notify("Selected participants were successfully deleted", "success", 2000)
      : notify(
        `Failed to delete following participants: ${getUndeletedUsernames()}`,
        "error",
        2000
      )
    setLoading(false)
    await fetchAllFarmers()
  }

  const onDelete = () => {
    const result = confirm(
      "Are you sure you want to delete selected participants?",
      "Delete participants"
    )
    result.then((dialogResult) => {
      dialogResult && handleDeleteFarmers()
    })
  }

  /**
   * END OF HANDLER SECTION
   */


  //render farmer cell in the list of farmers
  const renderCell = (props) => {
    const { data, value } = props
    const renderAvatar = () => {
      return props.data.photo ? (
        <div
          style={{
            background: `url(${data.photo}) no-repeat #fff`,
            backgroundSize: "cover",
          }}
          className={"user-image"}
        />
      ) : (
        <div
          style={{ background: getAvatarColor(value) }}
          className={"text-image"}
        >
          { getFirstLetters(data.firstName, data.lastName) }
        </div>
      )
    }

    return (
      <div className={"userinfo"}>
        <div className={"image-container"}>{ renderAvatar() }</div>
        <div className={"username"}>{props.value}</div>
      </div>
    )
  }

  const contentReady = (e) => {
    const columnChooserView = e.component.getView("columnChooserView")
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer()
      columnChooserView.render()
      columnChooserView._popupContainer.option("hideTopOverlayHandler", true)
      columnChooserView._popupContainer.option("position", {
        of: e.element,
        my: "right top",
        at: "right top",
        offset: "-20 -50"
      })
    }
  }

  return (
    <div>
      <div className={"contentBlock"}>
        <img src={FarmerIcon} alt="farmerIcon" />
        <h2>Participants</h2>
      </div>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Button
            text="Create a participant"
            onClick={handleCreateClick}
          />
          <Button
            text="Send participant url"
            onClick={handleSendSmsClick}
            disabled={isDisabled}
            loading={loading}
          />
          <Button
            text="Delete participants"
            onClick={onDelete}
            disabled={isDisabled}
            loading={loading}
          />

          <Table
            loading={loading}
            data={farmers}
            keyExpr={'userId'}
            hasSelection={true}
            hasEditing={true}
            hasFilterRow={true}
            hasColumnChoose={true}
            showColumnChooser={true}
            columns={columns}
            crudOptions={crudOptions}
            basePath={'edit-farmer/edit'}
            handleSendUrl={handleSendUrl}
            handleEditClick={handleEditClick}
            handleSelectionChange={handleSelectionChange}
            handleDelete={handleDelete}
            renderCell={renderCell}
            contentReady={contentReady}
          />

        </div>

        <MessagePopup
        options={[]}
          loading={loading}
          open={showSmsPopup}
          message={message}
          onClose={handleCloseSmsPopup}
          onMessageChange={handleMessageChange}
          onSendSms={handleSendSms}
          previewMessage={`Hello *user fullname*! ${message.length > 0 ? message : "*Your message*"
            } https://.../?user-id=...`}
        />
      </div>
    </div>
  )
}

export default Farmers
