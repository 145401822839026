import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";

import { signIn } from "../../api/auth";
import { useLogin } from "../../cookies";
import { useProgram } from '../../programs'
import { getForgotPasswordPath } from '../../app-routes';
import "./login-form.scss";


export default function LoginForm() {
  const program = useProgram();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const { setUser, setToken } = useLogin();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const { login, password } = formData.current;
      const result = await signIn(program.id, login, password);
      if (result.isOk) {
        setUser(result.data);
        setToken(result.data.token);
        setLoading(false);
      } else {
        setLoading(false);
        notify(result.message, "error", 2000);
      }
    },
    [setUser, setToken, program]
  );

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"login"}
          editorType={"dxTextBox"}
          editorOptions={loginEditorOptions}
        >
          <RequiredRule message="Login is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <Link to={getForgotPasswordPath(program.name)}>Forgot password</Link>
    </form>
  );
}

const loginEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Login",
  mode: "text",
};
const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};
