import { useCallback } from "react";
import { useCookies } from "react-cookie";

const USER = "user";
const TOKEN = "token";

// The token cookie is the same for the whole application,
// no matter what the route
const options = { path: "/" };

/**
 * Hook to manage token. It returns a tuple containing the value of the
 * token and functions to change and remove it.
 * It can be used like:
 *      const {token, setToken} = useToken()
 */

//Handle the creation of a cookie token for the user when he loggin
export const useLogin = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    USER,
    TOKEN
  ]);

  const setUser = useCallback(
    (user) => {
      delete user.photo
      setCookie(USER, user, options);
    },
    [setCookie]
  );

  const setToken = useCallback(
    (token) => {
      setCookie(TOKEN, token, options);
    },
    [setCookie]
  );

  const removeUser = useCallback(() => {
    removeCookie(USER, options);
    removeCookie(TOKEN, options);
  }, [removeCookie]);

  return {
    user: cookies.user,
    token: cookies.token,
    setUser,
    setToken,
    removeUser,
  };
};