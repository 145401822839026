import React from "react";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/user-panel";
import "./header.scss";
import { Template } from "devextreme-react/core/template";
import { useHistory } from "react-router-dom";
import suttiIcon from "../../resources/icons/logo.svg";
import lineIcon from "../../resources/icons/Line.svg";
import { getWelcomePath } from '../../app-routes'
import { useProgram } from '../../programs'


const Header = (props) => {
  const program = useProgram();
  const history = useHistory();
  const { menuToggleEnabled, title, toggleMenu } = props;

  const handleTitleClick = () => {
    history.push(getWelcomePath(program.name));
  };

  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={"before"} >
          <img className="iconSutti" src={suttiIcon} alt="logoSutti" width={45}/>&#160;
        </Item>
        <Item location={"before"} >
          <img className="line" src={lineIcon} alt="line" height={30}/>
        </Item>
        <Item
          location={"before"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
          onClick={handleTitleClick}
        />
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
};
export default Header;

Header.propTypes = {
  menuToggleEnabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
};
