import React, { useCallback, useRef, useState } from 'react'
import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import { forgotPassword } from "../../api/auth";
import { Link } from 'react-router-dom'
import { useProgram } from '../../programs'
import { getLoginPath } from '../../app-routes'

export default function ForgotPassword (){
  const program = useProgram();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const { login } = formData.current;
      const result = await forgotPassword(login, program.id);
      if (result.isOk) {
        setLoading(false);
        notify(`A password recovery link has been send by email to ${login}`, "success", 2000);
      } else {
        setLoading(false);
        notify(result.message, "error", 2000);
      }
    },
    [program.id]
  );


  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"login"}
          editorType={"dxTextBox"}
          editorOptions={loginEditorOptions}
        >
          <RequiredRule message="Login is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator width={"24px"} height={"24px"} visible={true} />
                ) : (
                  "Send reset password link"
                )}
              </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <Link to={getLoginPath(program.name)}>Login</Link>
    </form>
  )
}

const loginEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Login",
  mode: "text",
}

