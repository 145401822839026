import { getService, postService, deleteService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

/**
 * Returns buyers's prices info list if success else returns error message
 * @param {string} token
 * @param {string} pointOfSaleId
 * @param {string} productId
 */
export async function fetchPrices(token, pointOfSaleId, productId) {
  const url = `${baseUrl}/bo/pointsOfSale/${pointOfSaleId}/products/${productId}/prices`;
  const prices =  await getService(token, url);
  return prices
}

/**
 * Creates a price if does not exists else updates it and returns success if worked else returns error message
 * @param {string} token
 * @param {string} pointOfSaleId
 * @param {string} productId
 * @param {object} values
 */
export async function upsertPrice(token, pointOfSaleId, productId, values) {
  const url = `${baseUrl}/bo/pointsOfSale/${pointOfSaleId}/products/${productId}/prices`;
  return await postService(token, url, values);
}

/**
 * Delete a price if success else returns error message
 * @param {string} token
 * @param {string} pointOfSaleId
 * @param {string} productId
 * @param {string} priceId
 */
export async function deletePrice(token, pointOfSaleId, productId, priceId) {
  const url = `${baseUrl}/bo/pointsOfSale/${pointOfSaleId}/products/${productId}/prices?id=${priceId}`;
  return await deleteService(token, url);
}