import React from "react";
// import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import { Tooltip } from "devextreme-react/tooltip";

const Select = (props) => {
  const { options, value, onChange, id, tooltip, ...rest } = props;

  // const renderField = (data) => {
  //   return (
  //     <TextBox
  //       defaultValue={data && data.name}
  //       readOnly={true}
  //       stylingMode="outlined"
  //     />
  //   );
  // };

  const renderItem = (data) => {
    return data.name;
  };

  return (
    <div><SelectBox
      dataSource={options}
      elementAttr={{id: `${id}`}}
      displayExpr="name"
      valueExpr="value"
      value={value}
      searchExpr="name"
      searchEnabled={true}
      searchMode="startswith"
      showClearButton={true}
   //   fieldRender={renderField}
      itemRender={renderItem}
      stylingMode="outlined"
      onValueChanged={onChange}
      {...rest}
    />
     <Tooltip
                    target={`#${id}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>{`${tooltip}`}</div>
                  </Tooltip>
    </div>
  );
};

export default Select;
