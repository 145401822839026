import React, { useCallback, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  OverlayView
} from "@react-google-maps/api";
import markerIcon from "../../resources/icons/mapMarker.png";
import "./mapLayer.scss";


let mapGlobal = undefined
const MapLayer = ({ center, onClick, polygons }) => {

  const googleApiKey = React.useMemo(
    () => process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    []
  );

  const containerStyle = {
    width: "100%",
    height: "400px"
  };

  const mapOptions = {
    streetViewControl: false
  }

  const onLoad = useCallback((map) => {
    mapGlobal = map
    const bounds = new window.google.maps.LatLngBounds()
    if (center && polygons) {
      polygons.forEach(polygon => {
        polygon?.plotMarkers?.forEach(marker => {
          bounds.extend({
            lat: marker.lat,
            lng: marker.lng
          })
          // Reverse bound to be center
          bounds.extend({
            lat: 2 * center.lat - marker.lat,
            lng: 2 * center.lng - marker.lng
          })
        })
      })
    }
    map.fitBounds(bounds)
  }, [center, polygons])


  return (
    <LoadScript
      googleMapsApiKey={googleApiKey}
    >
      <GoogleMap
        id="Map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        options={mapOptions}
        onLoad={onLoad}
        onClick={onClick}
      >
        <Marker
          position={center}
          icon={markerIcon}
          title="farm"
        />
        {polygons.map(polygon =>
          <PolygonWithPopup
            key={polygon.id}
            polygon={polygon}
            onClick={onClick}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );

};



const PolygonWithPopup = ({ polygon, onClick }) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const [popupPosition, setPopupPosition] = useState();
  const options = {
    strokeColor: "purple",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true, // setting this to false creates a regression: popup displaying the plot nickname no longer shows
    editable: false,
    draggable: false,
    geodesic: false,
  };


  const overlayOptions = {
    zIndex: 1
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -(width),
    y: -(height),
  });

const addMarkerOnCoordinates = ((map) => {
  map = mapGlobal
  if (polygon.plotMarkers !== undefined && map !== undefined) {
    let infowindow = new window.google.maps.InfoWindow();
    let marker, i;
    for (i = 0; i < polygon.plotMarkers.length; i++) {
      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(polygon.plotMarkers[i].lat, polygon.plotMarkers[i].lng),
        map: map,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 4,
          fillColor: "#F00",
          fillOpacity: 0.4,
          strokeWeight: 0.4,
        },
         title: i.toString(),
        
      });
      
      window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {
          const contentString = "<h6>Point :" + i + "</h6> <br/>lat : " + polygon.plotMarkers[i].lat + "<br/>lgn : " + polygon.plotMarkers[i].lng
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }
  }
})
addMarkerOnCoordinates()

    return (
      <div>
        <Polygon
          paths={polygon.plotMarkers}
          options={options}
          onMouseOver={() => setMouseOver(true)}
          onMouseOut={() => setMouseOver(false)}
          onMouseMove={(e) => setPopupPosition(e.latLng)}
          onClick={onClick}
        >
        </Polygon>
        {isMouseOver &&
          <OverlayView
            position={popupPosition}
            mapPaneName={OverlayView.MARKER_LAYER}
            getPixelPositionOffset={getPixelPositionOffset}
            options={overlayOptions}
          >
            <div id="popupOverlay">
              <p>{polygon.nickname}</p>
            </div>
          </OverlayView>
        }
      </div>
    )
  };

  export default MapLayer;
