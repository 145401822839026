import { getService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

/**
 * Returns buyers's products list if success else returns error message
 * @param {string} token
 * @param {string} pointOfSaleId
 */
export async function fetchProductByPointOfSale(token, pointOfSaleId) {
  const url = `${baseUrl}/bo/pointsOfSale/${pointOfSaleId}/products`;
  const products = await getService(token, url);
  return products;
}
