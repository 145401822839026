import { forwardRef } from "react"
import Form, {
  Item,
  RequiredRule
} from "devextreme-react/form"
import './priceForm.scss'

const PriceForm = forwardRef((props, ref) => {
  const { formData, loading } = props

  const customizeItem = (item) => {
    if (item.dataField === "value") {
        item.validationRules = [{
            type: "required",
            message: "The value is required"
        }, {
            type: "pattern",
            pattern: "^[0-9]+.?[0-9]{1,2}+$",
            message: "The value should contain only digits and a single dot at most"
        }]
    }
  }

  const dateOptions = {
    displayFormat: 'MM/dd/yyy',
    min: new Date().toString(),
    dateSerializationFormat: 'yyyy-MM-dd'
  }

  return(
      <Form
        formData={formData}
        ref={ref}
        disabled={loading}
        colCount={3}
        labelLocation="top"
        labelMode="floating"
        width="100%"
      >
        <Item
          dataField={"value"}
          editorType={"dxNumberBox"}
          customizeItem={customizeItem}
        >
          <RequiredRule message="Price is required" />
        </Item>

        <Item dataField="startDate" editorType="dxDateBox" editorOptions={dateOptions}/>

        <Item dataField="endDate" editorType="dxDateBox" editorOptions={dateOptions} />
      </Form>
  )
});

export default PriceForm;