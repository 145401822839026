import axios from "axios";
import { getService } from "./api";

const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

//sing in for instructor  using axios run both on client and server
export async function signIn(programId, login, password) {
  try {
    let res = await axios.post(`${baseUrl}/bo/user/signIn`, {
      programId,
      staffLogin: login,
      staffPassword: password,
    });
    return {
      isOk: true,
      data: res.data,
    };
  } catch (e) {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function forgotPassword(staffLogin, program) {
  try {
    let res = await axios.post(`${baseUrl}/bo/user/forgot-password`, {
      staffLogin,
      program
    });
    return {
      isOk: true,
      data: res.data,
    };
  } catch (e) {
    const message = e.response?.data?.message
    return {
      isOk: false,
      message: "Send reset password failed" + (message ? " - "+message : ""),
    };
  }
}

export async function resetPassword(recoveryToken, staffPassword) {
  try {
    let res = await axios.post(`${baseUrl}/bo/user/reset-password`, {
      recoveryToken,
      staffPassword
    });
    return {
      isOk: true,
      data: res.data,
    };
  } catch (e) {
    const message = e.response?.data?.message
    return {
      isOk: false,
      message: "Reset password failed" + (message ? " - "+message : ""),
    };
  }
}

export async function fetchAllInstructors(token) {
  const url = `${baseUrl}/bo/users/getAllInstructorsUserInfo`;
  const fetchedInstructors = await getService(token, url);
  return fetchedInstructors;
}
