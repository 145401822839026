
import { useEffect, useState, useRef, useCallback, useMemo} from "react";
import { useHistory } from "react-router";
import {useParams, useLocation} from "react-router";
import { useProgram } from "../../programs";
import  { GroupItem, Form,RequiredRule,SimpleItem} from "devextreme-react/form";
import { TextBox, DateBox, NumberBox, TextArea, SelectBox, Tooltip, Validator} from "devextreme-react";
import { getCohortEditPath } from "../../app-routes";
import { fetchAllInstructors } from "../../api/auth";
import { useLogin } from "../../cookies";
import {FileUploader, Button} from "../../components";
import {toBase64 } from "../../utils/utils";
import notify from "devextreme/ui/notify";
import { createSession, getSessionById, updateSession } from "../../api/session";
import { getAvatarColor } from "../../utils/utils";
import { fetchCohortLocation } from "../../api/cohort";

const Session = () => {
  const { course, cohortId, id } = useParams();
  const { pathname, state} = useLocation();
  const history = useHistory();
  const initialInfoState = useMemo(() => ({
        name: null,
        cmsCourse: course,
        cohort: cohortId,
        date: new Date(),
        status : "PLANNED",
        gpsPoint : null,
        description: null,
        instructor: null,
        attendanceSheet: null
      }), [course, cohortId])

      const validationRules = {
        name: [{ type: "required", message: "Session name is required" }]
      }
    const [sessionInfo, setSessionInfo] = useState(initialInfoState);
    const [instructorsList, setInstructorsList] = useState();
    const [defaultLocation, setDefaultLocation] = useState({
      share : false,
      gpsPoint : null
    });
    const [sessionId, setSessionId] = useState();
    const isMounted = useRef(false)

    const program = useProgram()
    const {token} = useLogin();

    const isEdit = pathname.split("/").includes("edit");

    const cohortCourse = state ? state.cohortCourse : null;

    
    const CreateSession = async () => {
      // If default location is chosen, use cohort usual location
      const body  = defaultLocation.share ? {...sessionInfo, gpsPoint : defaultLocation.gpsPoint} : sessionInfo
      const res = await createSession(token, body)
      if (res.isOk) {
        notify("Session created", "success", 2000);
        history.push({ pathname : getCohortEditPath(program.name,cohortId), state : {back : "session"}});

      }else{
        notify("Failed to create session", "error", 2000);
      }
    }

    const EditSession = async () => {
      // If default location is chosen, use cohort usual location
      const body  = defaultLocation.share ? {...sessionInfo,  gpsPoint : defaultLocation.gpsPoint} : sessionInfo
      const res = await updateSession(token,sessionId, body)
      if (res.isOk) {
        notify("Session updated", "success", 2000);
        history.push({ pathname :getCohortEditPath(program.name,cohortId), state : {back : "session"}});

      }else{
        notify("Failed to create session", "error", 2000);
      }
    }

    const choices = {
      isEdit : {
        title : "Edit a session",
        button : "Validate",
        actionButton : EditSession
      },
      isCreate : {
        title : `Create a new Session`,
        button : "Validate", 
        actionButton : CreateSession
      }
    }
    
    const now = new Date();
    const dateTimeLabel = { 'aria-label': 'Date Time' };

    const handleChangeData = (key, value) => {
        if (key === 'date') {
          const valueDate = new Date(value)
          setSessionInfo({ ...sessionInfo, date: new Date(valueDate) });
        }else if (key === 'location') {
          const splittedValue = value.split(",")
          setSessionInfo({ ...sessionInfo, location: { longitude: splittedValue[1], latitude: splittedValue[0] } });
        }
        else{
          setSessionInfo({ ...sessionInfo, [key]: value });
        }
    }

    const fetchInstructors = useCallback(async () => {
      const res = await fetchAllInstructors(token);
      if (res.isOk) {
        const modifiedData = res.data.map((item) => ({
          ...item,
          userId: parseInt(item.userId)
        }));
        setInstructorsList(modifiedData);
      }
    }, [token]);
  
    const fetchSession = useCallback(async (id) => {
      const res = await getSessionById(token, id);
      if (res.isOk) {
        setSessionInfo(res.data);
      }
    }, [token]);

    const fetchDefaultLocation = useCallback(async () => {
      const res = await fetchCohortLocation(token, cohortId);
      if (res.isOk && res.data) {
        setDefaultLocation({
          share : true,
          gpsPoint : res.data.gps_point
        });
      }
    }, [token, cohortId]);


    useEffect( () => {
      async function fetchData() {
      if (!isMounted.current) {
        if(isEdit){
           setSessionId(id)
          await fetchSession(id);
        }else{
          await fetchDefaultLocation()
        }
        await fetchInstructors();
        isMounted.current = true
      }
    }
    fetchData()
    }, [isEdit, fetchInstructors, pathname, fetchSession, id, fetchDefaultLocation])

    const options = [
      { name: 'Planned', value: 'PLANNED' },
      { name: 'Held', value: 'HELD' }
    ];
    

    // Render the first name and lastname of instructors
    const dropBoxDisplay = (item) => {
      return item && `${item.firstName} ${item.lastName}`;
    };


    // Convert the attendance sheet to base64 string
    const handleFileChange = async (file) => {
      const base64String = file && await toBase64(file)
      handleChangeData("attendanceSheet", base64String);
    }

    // Display the first name and lastname of the chosen instructor
    const displayInstructorChosen = () => {
      return instructorsList.find(instructor => instructor.userId=== sessionInfo.instructor).firstName + " " +  instructorsList.find(instructor => instructor.userId === sessionInfo.instructor).lastName
    }

    // Validate the form (ex : name required, location format)
    const validateGroup = async (e) => {
      const res = await e.validationGroup.validate();
      if (res.brokenRules.length > 0) {
        notify("Please fill in all required details", "error", 4000);
      } else {
        if(isEdit){
          // use the function to edit
          await choices.isEdit.actionButton();
        }else{
          // use the function to create
          await choices.isCreate.actionButton();
        }
      }
    };

    const renderTitle = () => {
      const renderImage = () => {
        return cohortCourse.imageUrl ? (
          <div
            style={{
              background: `url(${cohortCourse.imageUrl}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        ) : (
          <div
            style={{ background: getAvatarColor(cohortCourse.title) }}
            className={"text-image"}
          >
            {cohortCourse.title.substring(0, 2)}
          </div>
        );
      };
  
      return (
        <div className={"userinfo"} >
          <div className={"image-container"}>{renderImage()}</div>
          <div>{isEdit ? choices.isEdit.title : choices.isCreate.title} for {cohortCourse.title}</div>
        </div>
      );
    };

    const handleBacktoSessions = () => {
      history.push({pathname :getCohortEditPath(program.name, cohortId), state : {back : "session"}})
    }

    const restoreDefaultLocation = () => {
      setDefaultLocation({
        ...defaultLocation, 
        share : true,
      })
    }

    const handleCoordinatesChange = (value, key) => {
      let coordinates;
        const gps = sessionInfo.gpsPoint?.coordinates
        if (key === 'long'){
          coordinates = {
            type: "Point",
            coordinates : [value === '' ? null : parseFloat(value), gps ? gps[1] : null]
          }
        }else if(key === 'lat') {
          coordinates = {
            type: "Point",
            coordinates : [ gps ? gps[0] : null, value === '' ? null : parseFloat(value)]
          }
        }
        if(coordinates.coordinates[0] === null && coordinates.coordinates[1] === null){
          coordinates = null
        }
        const updatedValues = {
          ...sessionInfo,
          gpsPoint: coordinates
        };
        setSessionInfo(updatedValues);
        setDefaultLocation({
          ...defaultLocation, 
          share : false,
        })
    }

    return (
      <>

      <div className="header">
        <h5 className={"content-block"}> {cohortCourse ? renderTitle() : isEdit ? choices.isEdit.title + course: choices.isCreate.title +course}</h5>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
            <div className="form-container"> 
            <Form formData={sessionInfo} validationGroup="SessionForm">
            <SimpleItem
                dataField="name"
                name = "name"
                render={() => (
                    <TextBox
                      id="name"
                      name = "name"
                      value={sessionInfo.name}
                      onValueChange={(value) =>
                        handleChangeData("name", value)
                      }
                      stylingMode="outlined"
                      validationRules={validationRules.name}
                    >
                    <Validator validationGroup={"SessionForm"}>
                      <RequiredRule  message={"Session's name is required."} />
                    </Validator>
                      <Tooltip
                      target="#name"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Name of the session</div>
                    </Tooltip>
                    </TextBox>
                )}
              />
              <SimpleItem
                dataField="date"
                name = "date"
                render={() => (
                  <DateBox
                  id="date"
                  name="date"
                  value={sessionInfo.date}
                  defaultValue={now}
                  inputAttr={dateTimeLabel}
                  type="datetime"
                  showClearButton={true}
                  onValueChange={(value) =>
                    handleChangeData("date", value)
                  }
                  displayFormat="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm" 
                >
                  <Tooltip
                      target="#date"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Date and time of the session</div>
                    </Tooltip>
                  </DateBox>
                )}
              />
              <SimpleItem
                dataField="numberOfHours"
                name = "numberOfHours"
                render={() => (
                  <NumberBox
                  id="numberOfHours"
                  value={sessionInfo.numberOfHours}
                  onValueChange={(value) =>
                    handleChangeData("numberOfHours", value)
                  }
                  stylingMode="outlined"
                  showSpinButtons={true}
                  showClearButton={true}
                  min={0}
                
                >
                  <Tooltip
                      target="#numberOfHours"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Number of session hours</div>
                    </Tooltip>
                </NumberBox>

                )}
              />

                <SimpleItem
                dataField="description"
                name = "description"
                render={() => (
                  <TextArea
                  id="description"
                  name="description"
                  height={90}
                  autoResizeEnabled={true}
                  onValueChange={(value) =>
                    handleChangeData("description", value)
                  }
                >
                      <Tooltip
                      target="#description"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Session's description</div>
                    </Tooltip>
                  </TextArea>
                )}
              />
              <SimpleItem
                dataField="status"
                name = "status"
                render={() => (
                  <SelectBox
                  id="status"
                  name="status"
                  dataSource={options}
                  displayExpr="name"
                  valueExpr="value"
                  value={sessionInfo.status}
                  searchExpr="name"
                  searchEnabled={true}
                  searchMode="startswith"
                  showClearButton={true}
                  defaultValue={options[0]}
                  onValueChange={(value) =>
                    handleChangeData("status", value)
                  }
                >
                      <Tooltip
                      target="#status"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Session's status</div>
                    </Tooltip>
                  </SelectBox>
                  )
                }
              />
          <GroupItem colCount={2}>
          <SimpleItem
            dataField={!sessionInfo.gpsPoint && defaultLocation.share && defaultLocation.gpsPoint ? "longitude (set by default cohort location)" : "longitude"}
            render={() => (
              <div>
                <TextBox
                  id="longitude"
                  value={ !sessionInfo.gpsPoint  && defaultLocation.share && defaultLocation.gpsPoint ? defaultLocation.gpsPoint?.coordinates[0] : sessionInfo.gpsPoint?.coordinates[0]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'long')}
                  stylingMode="outlined"
                  showClearButton={true}
                >
                </TextBox>
                <Tooltip
                  target="#longitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (longitude)</div>
                </Tooltip>
              </div>
            )}
          />
          <SimpleItem
            dataField={!sessionInfo.gpsPoint  && defaultLocation.share && defaultLocation.gpsPoint  ? "latitude (set by default cohort location)" : "latitude"}
            render={() => (
              <div>
                <TextBox
                  id="latitude"
                  value={!sessionInfo.gpsPoint && defaultLocation.share && defaultLocation.gpsPoint ? defaultLocation.gpsPoint?.coordinates[1] : sessionInfo.gpsPoint?.coordinates[1]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'lat')}
                  stylingMode="outlined"
                  showClearButton={true}
                >
                </TextBox>
                <Tooltip
                  target="#latitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (latitude)</div>
                </Tooltip>
              </div>
            )}
          />

          {/* Button to restore default location */}
          { !defaultLocation.share && defaultLocation.gpsPoint &&
            <SimpleItem
            render={() => (
              <div>
                <Button text={"Restore default location"} buttonStyle="secondary" onClick={restoreDefaultLocation}/>
              </div>
            )}
          />
          }

          </GroupItem>
              <SimpleItem
              dataField="instructor"
              name="instructor"
              render={() => (
              instructorsList && (
                <SelectBox
                  dataSource={instructorsList}
                  displayExpr={dropBoxDisplay}
                  valueExpr="userId"
                  placeholder={sessionInfo.instructor ? displayInstructorChosen(): "Select a value..."}
                  value={sessionInfo.instructor}
                  searchEnabled={true}
                  searchMode="startswith"
                  showClearButton={true}
                  defaultValue={instructorsList[0] ? instructorsList[0].userId : null}
                  onValueChange={(value) =>
                    handleChangeData("instructor", value)
                  }
                >
                    <Tooltip
                      target="#instructor"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Session's instructor</div>
                    </Tooltip>
                </SelectBox>
              )
            )}
          />
          <SimpleItem 
          dataField="attendanceSheet"
          name = "attendanceSheet">
              <FileUploader
                file={sessionInfo.attendanceSheet}
                label="attendance sheet"
                onFileChange={(value) => handleFileChange(value)}
                acceptPdf={false}
                isEditable={true}
              >
                <Tooltip
                      target="#attendanceSheet"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                    >
                      <div>Session's proof of attendanceSheet</div>
                    </Tooltip>
              </FileUploader>
            </SimpleItem>
          
             </Form> 
             <div className={"buttons"}>
             <Button
            text={isEdit ? choices.isEdit.button : choices.isCreate.button}
            validationGroup={"SessionForm"}
            onClick={validateGroup}
            useSubmitBehavior={true}

          />
          <Button
            text="Cancel"
            onClick={handleBacktoSessions}
            buttonStyle={"secondary"}
          />
          </div>
          </div>
          </div>
      </>
    );

}


export default Session;
