import { Tabs } from "devextreme-react";
import { default as React } from "react";
import { useLocation } from "react-router";
import "./cohort.scss";
import CoursesDetails from "./coursesDetails";
import CohortData from './cohortData'
import Sessions from "../sessions/sessions";

const tabs = [
  {
    id: 0,
    text: "Cohort Details",
    content: (props) => <CohortData {...props}/>,
  },
  {
    id: 1,
    text: "Courses Details",
    content: (props) => <CoursesDetails {...props}/>,
  },
  {
    id: 1,
    text: "Sessions",
    content: (props) => <Sessions {...props}/>,
  }
];

const Cohort = () => {
  const { pathname, state } = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const isEditMode = pathname.split("/").includes("edit");

  const title = isEditMode ? "Edit cohort" : "Create cohort";

  const wasInSession = state?.back === "session" ? true : false;

  React.useEffect(() => {
    if (wasInSession) {
      setSelectedIndex(2);
    }
  }, [wasInSession]);
  

  const handleTabChange = (args) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  };

  return (
    <>
      <h2 className={"content-block"}>{title}</h2>
      <div className={"content-block dx-card responsive-paddings"}>
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedIndex}
          onOptionChanged={handleTabChange}
          scrollingEnabled
          disabled={!isEditMode}
        />
        <div className={"dx-card responsive-paddings"}>
          {tabs[selectedIndex].content({
            isEditMode,
            isEditing: true,
            onTabChange: handleTabChange,
          })}
        </div>
      </div>
    </>
  );
};

export default Cohort;
