import { getService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

/**
 * Returns buyers's prices info list if success else returns error message
 * @param {string} token
 * @param {string} buyerId
 */
export async function fetchLocalBuyerPointsOfSale(token, buyerId) {
  const url = `${baseUrl}/bo/buyers/${buyerId}/pointsOfSale`;
  const pointsOfSale = await getService(token, url);
  return pointsOfSale;
}