import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ForgotPassword, ResetPassword } from './components';
import { useProgram } from './programs'
import { getForgotPasswordPath, getLoginPath, getResetPasswordPath } from './app-routes'

export default function UnauthenticatedContent() {
  const program = useProgram();
  return (
    <Switch>
      <Route exact path={getLoginPath(program.name)} >
        <SingleCard title="Sign In">
          <LoginForm />
        </SingleCard>
      </Route>
      <Route exact path={getForgotPasswordPath(program.name)}>
        <SingleCard title="Forgot Password">
          <ForgotPassword />
        </SingleCard>
      </Route>
      <Route exact path={getResetPasswordPath(program.name)}>
        <SingleCard title="Reset Password">
          <ResetPassword />
        </SingleCard>
      </Route>
      <Redirect to={getLoginPath(program.name)} />
    </Switch>
  );
}
