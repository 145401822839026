import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./user-panel.scss";
import { useLogin } from "../../cookies";
import { getFirstLetters } from "../../utils/utils";

export default function UserPanel({ menuMode }) {
  const { user, removeUser } = useLogin();

  const menuItems = useMemo(
    () => [
      {
        text: "Logout",
        icon: "runner",
        onClick: removeUser,
      },
    ],
    [removeUser]
  );

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div className={"user-image"}>
            {getFirstLetters(user.firstName, user.lastName)}
          </div>
        </div>
        <div className={"user-name"}>
          {`${user.firstName} ${user.lastName}`}
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}

UserPanel.propTypes = {
  menuMode: PropTypes.string.isRequired,
};
