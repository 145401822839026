import React from "react";
// import TextBox from "devextreme-react/text-box";
import { DropDownButton } from "devextreme-react";
import { Tooltip } from "devextreme-react/tooltip";

const Dropdown = (props) => {
  const { options, value, id, onChange, tooltip, ...rest } = props;

  // const renderField = (data) => {
  //   return (
  //     <TextBox
  //       defaultValue={data && data.name}
  //       readOnly={true}
  //       stylingMode="outlined"
  //     />
  //   );
  // };
  const buttonDropDownOptions = { width: 230, height: 180 };
  
//  const renderItem = (data) => {
//    return data.name;
//  };

  return (
    <div>
       <DropDownButton className="DropButtonStatus"
        elementAttr={{id: `${id}`}}
        dropDownOptions={buttonDropDownOptions}
        items={options}
        keyExpr="value"
        displayExpr="name"
        selectedItemKey={value}
        useSelectMode={true}
        onSelectionChanged={onChange}//a commenté pour compilé
        {...rest}
    />
     <Tooltip
                    target={`#${id}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>{`${tooltip}`}</div>
                  </Tooltip>
    </div>
  );
};

export default Dropdown;
