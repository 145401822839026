import { deleteService, getService, postService, putService } from './api'
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;
export async function getSessionsByCohort(token, cohortId ) {
    const url = `${baseUrl}/bo/sessions/${cohortId}`;
    const fetchedSessions = await getService(token, url)
    return fetchedSessions
  }

  export async function createSession(token, payload) {
    const url = `${baseUrl}/bo/session`;
    const createdSession = await postService(token, url, payload)
    return createdSession
  }

  export async function deleteSessions(token, params) {
    const url = `${baseUrl}/bo/session?cmsCourse=${params.cmsCourse}&cohort=${params.cohort}&sessionNumber=${params.sessionNumber}`;
    const createdSession = await deleteService(token, url)
    return createdSession
  }

  export async function getSessionById(token, sessionId) {
    const url = `${baseUrl}/bo/session/${sessionId}`;
    const response = await getService(token, url)
    return response
  }

  export async function getPresentUsers(token, sessionId) {
    const url = `${baseUrl}/bo/attendance/${sessionId}`;
    const usersPresent = await getService(token, url)
    return usersPresent
  }

  export async function SendAttendanceSheet(token, payload) {
    const url = `${baseUrl}/bo/attendance`;
    const response = await postService(token, url, payload)
    return response
  }

  export async function updateSession(token, sessionId, payload) {
    const url = `${baseUrl}/bo/session?sessionId=${sessionId}`;
    const response = await putService(token, url, payload)
    return response
  }

  export async function sendAlertSession(token, payload) {
    const url = `${baseUrl}/bo/session/alert`;
    const response = await postService(token, url, payload)
    return response
  }