import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { appInfo } from "./app-info";
import { appRoutes, getWelcomePath } from './app-routes'
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { useProgram } from './programs'

export default function Content() {
  const program = useProgram()

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        { appRoutes.map(({ path, component }) => (
          <Route exact key={path} path={path(program.name)} component={component} />
        ))}
        <Redirect to={getWelcomePath(program.name)} />
      </Switch>
    </SideNavBarLayout>
  );
}
