import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule, CompareRule } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import notify from 'devextreme/ui/notify'
import { resetPassword } from "../../api/auth";
import { sleep } from '../../utils/utils'
import { useProgram } from '../../programs'
import { getLoginPath } from '../../app-routes'

export default function ResetPassword (){
  const program = useProgram();
  const { search } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const recoveryToken = new URLSearchParams(search).get("recoveryToken")
      const { password } = formData.current;
      const result = await resetPassword(recoveryToken, password);
      if (result.isOk) {
        setLoading(false);
        notify(`Password reset with success`, "success", 2000);
        await sleep(1000)
        history.push(getLoginPath(program.name))
      } else {
        setLoading(false);
        notify(result.message, "error", 2000);
      }
    },
    [search, history, program]
  );


  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Login is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password2"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CompareRule comparisonTarget={() => formData.current.password} message="Passwords should be equals"/>
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator width={"24px"} height={"24px"} visible={true} />
                ) : (
                  "Reset password"
                )}
              </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  )
}

const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};

