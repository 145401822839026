import { getService } from './api'
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;
export async function refreshToken(token) {

    const url = `${baseUrl}/bo/refreshToken`;
    // Call to the api to refresh the token
    const newToken  = await getService(token, url);

    // Return token as a string instead of an object
    return newToken.data.toString()
}
