import Form, {
  GroupItem,
  Label,
  SimpleItem,
  PatternRule,
  NumericRule
} from "devextreme-react/form";
import { Tooltip } from "devextreme-react/tooltip";
import { TextBox, Validator } from "devextreme-react";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import PropTypes from "prop-types";
import React from "react";
import {
  createProductionData,
  fetchProductionDataById,
  updateProductionData,
} from "../../api/farmers";
import { Button } from "../../components";
import { useLogin } from "../../cookies";
import { processNullValues } from "../../utils/utils";
import { getBaseEditorOptions } from "./bioData";
import "./farmer.scss";

const initialProductionDataValues = {
  farmer: null,
  otherCrops: [
    {
      name: null,
      production: null,
      revenue: null,
      farmingCycles: [{ plotSize: null, numberOfCultivationMonths: null }],
    },
  ],
  totalWeeklyEmployeesWorkHours: null,
  weeklyTimeSpentOnFarmingMainCrop: null,
  mainCropIntrants: [
    {
      type: null,
      name: null,
      yearlyQuantity: null,
    },
  ],
  mainCropProductionCostsByMonth: null,
  mainCropLaborCostsByMonth: null,
  weeklyTimeSpentOnFarmingOtherCrops: null,
  cattleYearlyProduction: null,
  cattleByproductsYearlyProduction: null,
  cattleYearlyProductionRevenue: null,
  fishYearlyProduction: null,
  fishYearlyProductionRevenue: null,
  commerceYearlyProduction: null,
  commerceYearlyProductionRevenue: null,
  averageHoursSpentWorkingOfffarmPerWeek: null,
  averageWeeklyOfffarmRevenue: null,
  averageMonthlyMainCropProduction: null,
  averageMonthlyMainCropProductionRevenue: null,
  mainCropYearlyProduction: null,
  mainCropYearlyProductionRevenue: null,
  numberOfSalesTransactionsPerYear: null,
  amountOfSalesTransactionsPerYear: null
};

const validationRules = {
  yearlyQuantity: [
    { type: "numeric", message: "The value should be a number." },
  ],
  name: [{ type: "required", message: "Name is required." }],
  mainCropProductionCostsByMonth: [
    { type: "numeric", message: "The value should be a number." },
  ],
  production: [{ type: "numeric", message: "The value should be a number." }],
  revenue: [{ type: "numeric", message: "The value should be a number." }],
  plotSize: [{ type: "numeric", message: "The value should be a number." }],
  numberOfCultivationMonths: [
    { type: "numeric", message: "The value should be a number." },
  ],
  mainCropLaborCostsByMonth: [
    { type: "numeric", message: "The value should be a number." },
  ],
  weeklyTimeSpentOnFarmingOtherCrops: [
    { type: "numeric", message: "The value should be a number." },
  ],
  cattleYearlyProduction: [
    { type: "numeric", message: "The value should be a number." },
  ],
  cattleByproductsYearlyProduction: [
    { type: "numeric", message: "The value should be a number." },
  ],
  cattleYearlyProductionRevenue: [
    { type: "numeric", message: "The value should be a number." },
  ],
  fishYearlyProduction: [
    { type: "numeric", message: "The value should be a number." },
  ],
  fishYearlyProductionRevenue: [
    { type: "numeric", message: "The value should be a number." },
  ],
  averageHoursSpentWorkingOfffarmPerWeek: [
    { type: "numeric", message: "The value should be a number." },
  ],
  averageWeeklyOfffarmRevenue: [
    { type: "numeric", message: "The value should be a number." },
  ],
  mainCropProductionaverageMonthlyMainCropProductionCostsByMonth: [
    { type: "numeric", message: "The value should be a number." },
  ],
  averageMonthlyMainCropProductionRevenue: [
    { type: "numeric", message: "The value should be a number." },
  ],
  farmingCyclePlotSize: [
    {type: "numeric", message: "The value should be a number."}
  ],
  farmingCycleNbCultivationMonth: [
    {type: "numeric", message: "The value should be a number."}
  ],
  cropIntrantProduction: [
    {type: "numeric", message: "The value should be a number."}
  ],
  cropIntrantRevenue:[
    {type: "numeric", message: "The value should be a number."}
  ]
};

const ProductionData = ({ isEditing, onSubmit, farmerId }) => {
  const { token } = useLogin();
  const [loading, setLoading] = React.useState(false);
  const [hasProdValues, setHasProdValues] = React.useState(false);
  const [prodDataValues, setProdDataValues] = React.useState(
    initialProductionDataValues
  );

  const fetchProdData = React.useCallback(async () => {
    const fetchedProdData = await fetchProductionDataById(token, farmerId);

    if (fetchedProdData.isOk) {
      setProdDataValues(fetchedProdData.data);
      setHasProdValues(true);
    } else {
      setProdDataValues({ ...initialProductionDataValues, farmer: farmerId });
    }
  }, [farmerId, token]);

  React.useEffect(() => {
    fetchProdData();
  }, [fetchProdData]);

  const handleAddMainCropIntrant = () => {
    const updatedValues = {
      ...prodDataValues,
      mainCropIntrants: prodDataValues.mainCropIntrants.concat({
        type: null,
        name: null,
        yearlyQuantity: null,
      }),
    };

    setProdDataValues(updatedValues);
  };

  const handleAddFarmingCycle = (indexToUpdate) => {
    const updatedValues = {
      ...prodDataValues,
      otherCrops: prodDataValues.otherCrops.map((other, index) => {
        if (index === indexToUpdate) {
          return {
            ...other,
            farmingCycles: other.farmingCycles.concat({
              plotSize: null,
              numberOfCultivationMonths: null,
            }),
          };
        }
        return other;
      }),
    };

    setProdDataValues(updatedValues);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (hasProdValues) {
      const valuesToSend = processNullValues(prodDataValues);
      const res = await updateProductionData(token, valuesToSend);
      if (res.isOk) {
        notify("Production data was successfully updated", "success", 2000);
        await fetchProdData();
        onSubmit();
      } else {
        notify(res.message, "error", 2000);
      }
    } else {
      const res = await createProductionData(token, prodDataValues);
      if (res.isOk) {
        notify("Production data was successfully created", "success", 2000);
        await fetchProdData();
        onSubmit();
      } else {
        notify(res.message, "error", 2000);
      }
    }
    setLoading(false);
  };

  const validateGroup = async (e) => {
    if (e.validationGroup) {
      const res = await e.validationGroup.validate();
      return res.brokenRules.length > 0
        ? notify("Please fill in all required details", "error", 4000)
        : await handleSubmit();
    }

    return await handleSubmit();
  };

  const handleAddOtherCropDetails = () => {
    const updatedValues = {
      ...prodDataValues,
      otherCrops: prodDataValues.otherCrops.concat({
        name: null,
        production: null,
        revenue: null,
        farmingCycles: [{ plotSize: null, numberOfCultivationMonths: null }],
      }),
    };

    setProdDataValues(updatedValues);
  };

  const handleRemoveCropIntrant = (indexToRemove) => {
    const updatedValues = {
      ...prodDataValues,
      mainCropIntrants: prodDataValues.mainCropIntrants.filter(
        (crop, index) => index !== indexToRemove
      ),
    };

    setProdDataValues(updatedValues);
  };

  const handleRemoveFarmingCycle = (cropIndex, farmingIndexToRemove) => {
    const updatedValues = {
      ...prodDataValues,
      otherCrops: prodDataValues.otherCrops.reduce((acc, crop, index) => {
        if (index === cropIndex) {
          const updatedCrop = {
            ...crop,
            farmingCycles: crop.farmingCycles.filter(
              (cycle, cycleIndex) => cycleIndex !== farmingIndexToRemove
            ),
          };
          return acc.concat(updatedCrop);
        }
        return acc;
      }, []),
    };

    setProdDataValues(updatedValues);
  };

  const handleRemoveOtherCrop = (indexToRemove) => {
    const updatedValues = {
      ...prodDataValues,
      otherCrops: prodDataValues.otherCrops.filter(
        (crop, index) => index !== indexToRemove
      ),
    };

    setProdDataValues(updatedValues);
  };


  // Update data for Farming Cycles
  // field : field in the farming cycle to be updated
  // cropIndex : index of the farmer crop concerned
  // cycleIndex : index of the farming cycle
  // newValue : new value to be updated
  const handleFarmerCycleFieldChange = (field, cropIndex, cycleIndex, newValue) => {
    const updatedValues = {
      ...prodDataValues,
      // Find the right farm crop to update
      otherCrops: prodDataValues.otherCrops.map((otherCrop, index) => {
        if (index === cropIndex) {
          return {
            ...otherCrop,
            // Find the right farming cycle to update
            farmingCycles: otherCrop.farmingCycles.map((farmingCycle, i) => {
              if (i === cycleIndex) {
                return {
                  ...farmingCycle,
                  // Update the field
                  [field]: newValue,
                };
              }
              return farmingCycle;
            }),
          };
        }
        return otherCrop;
      }),
    };
    setProdDataValues(updatedValues);
  };
  

  return (
    <>
      <Form formData={prodDataValues} validationGroup={"productionDataGroup"}>
        <GroupItem cssClass="first-group" caption="Main crop details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="farmer"
              editorOptions={{
                disabled: true,
                stylingMode: "outlined",
              }}
            />
          </GroupItem>
          <GroupItem colCount={2}>
          <SimpleItem
              dataField="mainCropYearlyProduction"
              render={() => (
                <div>
                  <TextBox
                    id="mainCropYearlyProduction"
                    value={prodDataValues.mainCropYearlyProduction}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        mainCropYearlyProduction: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#mainCropYearlyProduction"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Main crop yearly production</div>
                  </Tooltip>
                </div>
              )}
            />
          <SimpleItem
              dataField="mainCropYearlyProductionRevenue"
              render={() => (
                <div>
                  <TextBox
                    id="mainCropYearlyProductionRevenue"
                    value={prodDataValues.mainCropYearlyProductionRevenue}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        mainCropYearlyProductionRevenue: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#mainCropYearlyProductionRevenue"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Main crop yearly production revenue</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="numberOfSalesTransactionsPerYear"
              render={() => (
                <div>
                  <TextBox
                    id="numberOfSalesTransactionsPerYear"
                    value={prodDataValues.numberOfSalesTransactionsPerYear}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        numberOfSalesTransactionsPerYear: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                    <NumericRule />
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be a number"
                      />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#numberOfSalesTransactionsPerYear"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number of sales transactions per year</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="amountOfSalesTransactionsPerYear"
              render={() => (
                <div>
                  <TextBox
                    id="amountOfSalesTransactionsPerYear"
                    value={prodDataValues.amountOfSalesTransactionsPerYear}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        amountOfSalesTransactionsPerYear: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#amountOfSalesTransactionsPerYear"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Amount of sales transactions per year</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="mainCropProductionCostsByMonth"
              render={() => (
                <div>
                  <TextBox
                    id="mainCropProductionCostsByMonth"
                    value={prodDataValues.mainCropProductionCostsByMonth}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        mainCropProductionCostsByMonth: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator
                      validationGroup={"mainCropProductionCostsByMonth"}
                    >
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#mainCropProductionCostsByMonth"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Main Crop Production Costs By Month</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="mainCropLaborCostsByMonth"
              render={() => (
                <div>
                  <TextBox
                    id="mainCropLaborCostsByMonth"
                    value={prodDataValues.mainCropLaborCostsByMonth}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        mainCropLaborCostsByMonth: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"productionDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#mainCropLaborCostsByMonth"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Main Crop Labor Costs By Month</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="averageMonthlyMainCropProduction"
              render={() => (
                <div>
                  <TextBox
                    id="averageMonthlyMainCropProduction"
                    value={prodDataValues.averageMonthlyMainCropProduction}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        averageMonthlyMainCropProduction: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"productionDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#averageMonthlyMainCropProduction"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Average Monthly Main Crop Production</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="averageMonthlyMainCropProductionRevenue"
              render={() => (
                <div>
                  <TextBox
                    id="averageMonthlyMainCropProductionRevenue"
                    value={
                      prodDataValues.averageMonthlyMainCropProductionRevenue
                    }
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        averageMonthlyMainCropProductionRevenue: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"productionDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#averageMonthlyMainCropProductionRevenue"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Average Monthly Main Crop Production Revenue</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="weeklyTimeSpentOnFarmingMainCrop"
              render={() => (
                <div>
                  <TextBox
                    id="weeklyTimeSpentOnFarmingMainCrop"
                    value={prodDataValues.weeklyTimeSpentOnFarmingMainCrop}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        weeklyTimeSpentOnFarmingMainCrop: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"productionDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#weeklyTimeSpentOnFarmingMainCrop"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Weekly Time Spent On Farming Main Crop</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="totalWeeklyEmployeesWorkHours"
              render={() => (
                <div>
                  <TextBox
                    id="totalWeeklyEmployeesWorkHours"
                    value={prodDataValues.totalWeeklyEmployeesWorkHours}
                    onValueChange={(value) =>
                      setProdDataValues({
                        ...prodDataValues,
                        totalWeeklyEmployeesWorkHours: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"productionDataGroup"}>
                      <NumericRule />
                    </Validator>
                  </TextBox>
                  <Tooltip
                    target="#totalWeeklyEmployeesWorkHours"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Total Weekly Employees Work Hours</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem name={"mainCropIntrants"}>
            {prodDataValues.mainCropIntrants.map((crop, index) => (
              <GroupItem colCount={3} key={index}>
                <SimpleItem
                  key={`mainCropIntrants${index}.name`}
                  dataField={`mainCropIntrants[${index}].name`}
                  editorOptions={{
                    value: crop.name,
                    placeholder:"indicate the main crop intrant name",
                    ...getBaseEditorOptions(!isEditing),
                  }}
                  validationRules={validationRules.name}
                >
                  <Label text={`Main crop intrant ${index + 1} name`} />
                </SimpleItem>
                <SimpleItem
                  key={`mainCropIntrants${index}.type`}
                  dataField={`mainCropIntrants[${index}].type`}
                  editorOptions={{
                    value: crop.type,
                    placeholder:"indicate the type of crop",
                    ...getBaseEditorOptions(!isEditing),
                  }}
                >
                  <Label text={`Main crop intrant ${index + 1} type`} />
                </SimpleItem>
                <SimpleItem
                  key={`mainCropIntrants${index}.yearlyQuantity`}
                  dataField={`mainCropIntrants[${index}].yearlyQuantity`}
                  editorOptions={{
                    value: crop.yearlyQuantity,
                    placeholder:"indicate quantity per year",
                    ...getBaseEditorOptions(!isEditing),
                  }}
                  validationRules={validationRules.yearlyQuantity}
                >
                  <Label
                    text={`Main crop intrant ${index + 1} quantity per year`}
                  />
                </SimpleItem>
                <SimpleItem
                  render={() => (
                    <Button
                      text={"Remove crop intrant"}
                      onClick={() => handleRemoveCropIntrant(index)}
                      disabled={!isEditing}
                    />
                  )}
                />
              </GroupItem>
            ))}
            <SimpleItem
              render={() => (
                <Button
                  text={"Add main crop intrant"}
                  onClick={handleAddMainCropIntrant}
                  disabled={!isEditing}
                />
              )}
            />
          </GroupItem>
          <GroupItem cssClass="second-group" caption="Other crop details">
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="weeklyTimeSpentOnFarmingOtherCrops"
                render={() => (
                  <div>
                    <TextBox
                      id="weeklyTimeSpentOnFarmingOtherCrops"
                      value={prodDataValues.weeklyTimeSpentOnFarmingOtherCrops}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          weeklyTimeSpentOnFarmingOtherCrops: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#weeklyTimeSpentOnFarmingOtherCrops"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Weekly Time Spent On Farming Other Crops</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
            <GroupItem name={"otherCrops"}>
              {prodDataValues.otherCrops.map((crop, index) => (
                <GroupItem key={index}>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      key={`otherCrops${index}.name`}
                      dataField={`otherCrops[${index}].name`}
                      editorOptions={{
                        value: crop.name,
                        placeholder:"indicate other crop name",
                        ...getBaseEditorOptions(!isEditing),
                      }}
                      validationRules={validationRules.name}
                    >
                      <Label text={`Other crop ${index + 1} name`} />
                    </SimpleItem>
                    <SimpleItem
                      key={`otherCrops${index}.production`}
                      dataField={`otherCrops[${index}].production`}
                      editorOptions={{
                        value: crop.production,
                        placeholder:"indicate intrant production",
                        ...getBaseEditorOptions(!isEditing),
                      }}
                      validationRules={validationRules.cropIntrantProduction}
                    >
                      <Label
                        text={`Other crop intrant ${index + 1} production`}
                      />
                    </SimpleItem>
                    <SimpleItem
                      key={`otherCrops${index}.revenue`}
                      dataField={`otherCrops[${index}].revenue`}
                      editorOptions={{
                        value: crop.revenue,
                        placeholder:"indicate intrant revenue",
                        ...getBaseEditorOptions(!isEditing),
                      }}
                      validationRules={validationRules.cropIntrantRevenue}
                    >
                      <Label text={`Other crop intrant ${index + 1} revenue`} />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem name={`otherCrops[${index}].farmingCycles`}>
                    {crop.farmingCycles.map((cycle, cycleIndex) => (
                      <GroupItem key={cycleIndex} colCount={2}>
                        <SimpleItem
                          key={`farmingCycles${cycleIndex}.plotSize`}
                          dataField={`farmingCycles[${cycleIndex}].plotSize`}
                          render={() => (
                            <div>
                              <TextBox
                                id={`farmingCycles[${cycleIndex}].plotSize`}
                                value={cycle.plotSize}
                                onValueChange={(value) => handleFarmerCycleFieldChange("plotSize",index,cycleIndex,parseFloat(value))}
                                stylingMode="outlined"
                                disabled={!isEditing}
                                placeholder="indicate the plot size of the farming cycle"
                                validationRules={validationRules.farmingCyclePlotSize}
                              >
                            <Validator>
                                <NumericRule />
                            </Validator>
                              </TextBox>
                              <Tooltip
                                target={`#farmingCycles.plotSize`}
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                              >
                                <div>Plot size of the farming cycles</div>
                              </Tooltip>
                            </div>
                          )}
                        >
                          <Label
                            text={`Farming cycle ${cycleIndex + 1} plotSize`}
                          />
                        </SimpleItem>
                        <SimpleItem
                          key={`farmingCycles${cycleIndex}.numberOfCultivationMonths`}
                          dataField={`farmingCycles[${cycleIndex}].numberOfCultivationMonths`}
                          render={() => (
                            <div>
                              <TextBox
                                id={`farmingCycles${cycleIndex}.numberOfCultivationMonths`}
                                value={cycle.numberOfCultivationMonths}
                                onValueChange={(value) => handleFarmerCycleFieldChange("numberOfCultivationMonths",index,cycleIndex,parseFloat(value))}
                                stylingMode="outlined"
                                disabled={!isEditing}
                                placeholder="indicate number of cultivation months"
                                validationRules={validationRules.farmingCyclePlotSize}
                              >
                            <Validator validationGroup={"productionDataGroup"}>
                                <NumericRule />
                            </Validator>
                              </TextBox>
                              <Tooltip
                                target={`#farmingCycles.numberOfCultivationMonths`}
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                              >
                                <div>Number of cultivation months</div>
                              </Tooltip>
                            </div>
                          )}
                        >
                          <Label
                            text={`Farming cycle ${
                              cycleIndex + 1
                            } number of cultivation months`}
                          />
                        </SimpleItem>
                        <GroupItem>
                          {cycleIndex !== 0 && (
                            <SimpleItem
                              render={() => (
                                <Button
                                  text={"Remove farming cycle"}
                                  onClick={() =>
                                    handleRemoveFarmingCycle(index, cycleIndex)
                                  }
                                  disabled={!isEditing}
                                />
                              )}
                            />
                          )}
                        </GroupItem>
                      </GroupItem>
                    ))}
                  </GroupItem>
                  <SimpleItem
                    render={() => (
                      <Button
                        text={"Add farming cycle intrant"}
                        onClick={() => handleAddFarmingCycle(index)}
                        disabled={!isEditing}
                      />
                    )}
                  />
                  <SimpleItem
                    render={() => (
                      <Button
                        text={`Remove other crop n°${index + 1}`}
                        onClick={() => handleRemoveOtherCrop(index)}
                        disabled={!isEditing}
                      />
                    )}
                  />
                </GroupItem>
              ))}
              <SimpleItem
                render={() => (
                  <Button
                    text={"Add other crop"}
                    onClick={handleAddOtherCropDetails}
                    disabled={!isEditing}
                  />
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem cssClass="first-group" caption="Cattle details">
            <GroupItem colCount={3}>
              <SimpleItem
                dataField="cattleYearlyProduction"
                render={() => (
                  <div>
                    <TextBox
                      id="cattleYearlyProduction"
                      value={prodDataValues.cattleYearlyProduction}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          cattleYearlyProduction: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#cattleYearlyProduction"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Cattle Yearly Production</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="cattleByproductsYearlyProduction"
                render={() => (
                  <div>
                    <TextBox
                      id="cattleByproductsYearlyProduction"
                      value={prodDataValues.cattleByproductsYearlyProduction}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          cattleByproductsYearlyProduction: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#cattleByproductsYearlyProduction"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Cattle By products Yearly Production</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="cattleYearlyProductionRevenue"
                render={() => (
                  <div>
                    <TextBox
                      id="cattleYearlyProductionRevenue"
                      value={prodDataValues.cattleYearlyProductionRevenue}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          cattleYearlyProductionRevenue: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#cattleYearlyProductionRevenue"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Cattle Yearly Production Revenue</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem cssClass="first-group" caption="Fish details">
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="fishYearlyProduction"
                render={() => (
                  <div>
                    <TextBox
                      id="fishYearlyProduction"
                      value={prodDataValues.fishYearlyProduction}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          fishYearlyProduction: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#fishYearlyProduction"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Fish Yearly Production</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="fishYearlyProductionRevenue"
                render={() => (
                  <div>
                    <TextBox
                      id="fishYearlyProductionRevenue"
                      value={prodDataValues.fishYearlyProductionRevenue}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          fishYearlyProductionRevenue: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#fishYearlyProductionRevenue"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Fish Yearly Production Revenue</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem cssClass="first-group" caption="Commerce details">
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="commerceYearlyProduction"
                render={() => (
                  <div>
                    <TextBox
                      id="commerceYearlyProduction"
                      value={prodDataValues.commerceYearlyProduction}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          commerceYearlyProduction: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                  <Validator validationGroup={"productionDataGroup"}>
                    <NumericRule />
                  </Validator>
                </TextBox>
                    <Tooltip
                      target="#commerceYearlyProduction"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Commerce Yearly Production</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="commerceYearlyProductionRevenue"
                render={() => (
                  <div>
                    <TextBox
                      id="commerceYearlyProductionRevenue"
                      value={prodDataValues.commerceYearlyProductionRevenue}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          commerceYearlyProductionRevenue: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#commerceYearlyProductionRevenue"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Commerce Yearly Production Revenue</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem cssClass="first-group" caption="Off farm revenue details">
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="averageHoursSpentWorkingOfffarmPerWeek"
                render={() => (
                  <div>
                    <TextBox
                      id="averageHoursSpentWorkingOfffarmPerWeek"
                      value={
                        prodDataValues.averageHoursSpentWorkingOfffarmPerWeek
                      }
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          averageHoursSpentWorkingOfffarmPerWeek: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#averageHoursSpentWorkingOfffarmPerWeek"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Average Hours Spent Working Off farm Per Week</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="averageWeeklyOfffarmRevenue"
                render={() => (
                  <div>
                    <TextBox
                      id="averageWeeklyOfffarmRevenue"
                      value={prodDataValues.averageWeeklyOfffarmRevenue}
                      onValueChange={(value) =>
                        setProdDataValues({
                          ...prodDataValues,
                          averageWeeklyOfffarmRevenue: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={!isEditing}
                    >
                      <Validator validationGroup={"productionDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#averageWeeklyOfffarmRevenue"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Average Weekly Off farm Revenue</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
        </GroupItem>
      </Form>
      {isEditing && (
        <div className={"buttons"}>
          <Button
            text="Validate"
            validationGroup={"productionDataGroup"}
            onClick={validateGroup}
            loading={loading}
            useSubmitBehavior={true}
          />
          <Button text="Cancel" onClick={onSubmit} buttonStyle={"secondary"} />
        </div>
      )}
    </>
  );
};

export default ProductionData;

ProductionData.propTypes = {
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  farmerId: PropTypes.string,
};
