import { Template } from "devextreme-react/core/template";
import Drawer from "devextreme-react/drawer";
import PropTypes from "prop-types";
import ScrollView from "devextreme-react/scroll-view";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Header, SideNavigationMenu } from "../../components";
import { useScreenSize } from "../../utils/media-query";
import { useMenuPatch } from "../../utils/patches";
import "./side-nav-outer-toolbar.scss";

const SideNavOuterToolbar = ({ title, children }) => {
  const scrollViewRef = useRef(null);
  const history = useHistory();
  const { isXSmall, isLarge, isXLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(
    isLarge || isXLarge ? MenuStatus.Opened : MenuStatus.Closed
  );

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge && !isXLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return true;
  }, [isLarge, isXLarge]);

  const onNavigationChanged = useCallback(
    ({ itemData: { path }, event, node }) => {
      if (menuStatus === MenuStatus.Closed || !path || node.selected) {
        event.preventDefault();
        return;
      }

      history.push(path);
      scrollViewRef.current.instance.scrollTo(0);

      if ((!isLarge && !isXLarge) || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event.stopPropagation();
      }
    },
    [history, menuStatus, isLarge, isXLarge]
  );

  return (
    <div className={"side-nav-outer-toolbar"}>
      <Header
        className={"layout-header"}
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
      />
      <Drawer
        className={["drawer", patchCssClass].join(" ")}
        position={"before"}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge || isXLarge ? "shrink" : "overlap"}
        revealMode={isXSmall ? "slide" : "expand"}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={!(isLarge || isXLarge)}
        opened={menuStatus !== MenuStatus.Closed}
        template={"menu"}
      >
        <div className={"container"}>
          <ScrollView ref={scrollViewRef}>
            <div className={"content"}>
              {React.Children.map(children, (item) => {
                return item;
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={"menu"}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          ></SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );
};

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};

export default SideNavOuterToolbar;

SideNavOuterToolbar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
