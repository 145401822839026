import { getService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

export async function getAllCourses(token, programId) {
  const url = `${baseUrl}/bo/library/${programId}/courses`;
  const fetchedCourses = await getService(token, url);
  return fetchedCourses;
}

export async function getCourseStructure(token, courseSlug, programId ) {
  const url = `${baseUrl}/bo/library/${programId}/courses/${courseSlug}`;
  const fetchedCourses = await getService(token, url)
  return fetchedCourses
}
